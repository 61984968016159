import React, { useEffect, useState } from 'react'
import ContentWrapper from '../components/contentWrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom'
import { FiMenu } from "react-icons/fi";
import { AiTwotoneStar } from "react-icons/ai";
import Img from '../components/lazyLoadImage/Img';
import { toast } from 'react-toastify';
import { fetchDataFromApi, productFilterByCategoryAPi } from '../utils/api';
import { numberFormat } from '../components/numberFormate';
import { useCart } from "react-use-cart";
import { MetaTags } from 'react-meta-tags';
import TagManager from 'react-gtm-module'
import { FaMinus, FaPlus } from 'react-icons/fa';

const AyurvedaProducts = () => {
    const { addItem } = useCart();
    const navigate = useNavigate();
    const pathname = window.location.href;
    const userid = JSON.parse(localStorage.getItem('userdata'))?.id;
    const [mobileFilter, setMobileFilter] = useState(true);

    const [productDisplayView, setProductDisplayView] = useState(false);

    const productViewDown = () => {
        setProductDisplayView(true);
    }
    const productViewLeft = () => {
        setProductDisplayView(false);
    }
    const [product, setProduct] = useState([]);
    const [category, setCategory] = useState([]);
    const [producttitle, setProductTitle] = useState([]);

    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/img/product/";

    useEffect(() => {
        getProduct();
        getCategory();
        getProductPageTilte();
    }, [])
    const openMobileFilter = () => {
        setMobileFilter(true)
    }
    const getProductPageTilte = () => {
        fetchDataFromApi('get-product-page-title').then((res) => {
            if (res.status === 1) {
                setProductTitle(...[res.data]);
            }
        })
    }
    const getProduct = (catid) => {
        if (catid) {
            if (catid === "all") {
                fetchDataFromApi('home-starter-product').then((res) => {
                    if (res.status === 1) {
                        setProduct(...[res.data]);
                    }
                })
            }
            else {
                productFilterByCategoryAPi(`product-ayurvedic-filter-by-category/${catid}`).then((res) => {
                    if (res.data.status === 1) {
                        setProduct(...[res.data.data]);
                    }
                })
            }
        }
        else {
            fetchDataFromApi('home-starter-product').then((res) => {
                if (res.status === 1) {
                    setProduct(...[res.data]);
                }
            })
        }
    };

    const getCategory = () => {
        fetchDataFromApi('home-category').then((res) => {
            if (res.status === 1) {
                setCategory(...[res.data]);
            }
        })
    }
    const addToCart = (product) => {
        addItem(product);
        toast.success("Product has been added in your cart!");
        TagManager.dataLayer({
            dataLayer: {
                event: "add_to_cart",
                pagePath: pathname,
                pageTitle: producttitle?.product_heading,
                eventModel: {
                    ecomm_totalvalue: product.price,
                    ecomm_pagetype: "Cart",
                    value: product.price,
                    currency: "INR",
                    items: [
                        {
                            id: product.id,
                            name: product.name,
                            brand: "Satkartar",
                            price: product.price,
                            quantity: 1,
                        }
                    ]
                }
            },
        });
    }
    const buyItNow = (product) => {
        addItem(product);
        TagManager.dataLayer({
            dataLayer: {
                event: "begin_checkout",
                pagePath: pathname,
                pageTitle: producttitle?.product_heading,
                eventModel: {
                    ecomm_totalvalue: product.price,
                    ecomm_pagetype: "Cart",
                    value: product.price,
                    currency: "INR",
                    items: [
                        {
                            id: product.id,
                            name: product.name,
                            brand: "Satkartar",
                            price: product.price,
                            quantity: 1,
                        }
                    ]
                }
            },
        });
        if (userid) {
            navigate("/delivery-address");
        }
        else {
            navigate("/checkout-login");
        }
    }
    return (
        <div className="productSection">
            <MetaTags>
                <title>{producttitle?.product_heading}</title>
                <link rel="canonical" href={pathname} />
                <meta name="title" content={producttitle?.product_heading} />
                <meta name="description" content={producttitle?.product_desc} />
                <meta name="keywords" content="" />
                <meta property="og:url" content={pathname} />
                <meta property="og:title" content={producttitle?.product_heading} />
                <meta property="og:description"
                    content={producttitle?.product_desc} />
                <meta name="description" content={producttitle?.product_desc} />
            </MetaTags>
            <div className="breadcrumbs">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page"><span>Home</span></Link>
                        </li>
                        <li className="items product"><span>Product</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12">
                <ContentWrapper>
                    <div className="row">
                        <div className="col-md-3 col-sm-12">
                            <div className="shop-widget">
                                <h6 className="shop-widget-title">
                                    Filter by Probelm
                                    {mobileFilter === false ?
                                        <FaMinus className="btnFilter" onClick={openMobileFilter} />
                                        :
                                        <FaPlus className="btnFilter" onClick={() => setMobileFilter(false)} />
                                    }
                                </h6>
                                <form method="post" className={`${mobileFilter ? "filterForm" : ""}`}>
                                    <ul className="shop-widget-list shop-widget-scroll" style={{ overflowY: "unset", height: "unset" }}>
                                        {category?.map((category, index) => (
                                            <li key={index}>
                                                <div className="shop-widget-content">
                                                    <input type="radio" id={`category-${category?.id}`} value={category?.id} name="pcategory" onChange={() => getProduct(category.id)} />
                                                    <label htmlFor={`category-${category?.id}`}>{category?.category_name}</label>
                                                </div>
                                            </li>
                                        ))}
                                        <li>
                                            <div className="shop-widget-content">
                                                <input type="radio" id="all" value="all" name="pcategory" onChange={() => getProduct("all")} />
                                                <label htmlFor="all">All</label>
                                            </div>
                                        </li>
                                    </ul>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-9 col-sm-12">
                            <div className="productTopBar">
                                <div className="productViewTitle">
                                    <h4>Ayurveda Products</h4>
                                </div>
                                <div className="productViewIcon">
                                    <FiMenu onClick={productViewDown} className="productDown" />
                                    <FiMenu onClick={productViewLeft} className="productLeft" />
                                </div>
                            </div>
                            <div className="productView mt-4">
                                <div className="col-md-12 col-sm-12">
                                    <div className="row">
                                        {product ? product.map((product, index) => (
                                            <div className={`${productDisplayView ? "productLeftView col-md-12 col-sm-12" : "productDownView col-md-4 col-sm-12"}`} key={index}>
                                                <div className="appSinglePricingCard productPageCard">
                                                    <div className="pricingTopContent">
                                                        <div className="productImg">
                                                            <Link to={`/product/${product?.product_slug_url}`}>
                                                                <Img src={BASE_URL + product?.image} alt={product?.product_alt_name}></Img>
                                                            </Link>
                                                        </div>
                                                        <div className="productContentList">
                                                            <div className="pricingList">
                                                                <Link to={`/product/${product?.product_slug_url}`}>
                                                                    <h4 className="title productnames">{product?.page_title}</h4>
                                                                </Link>
                                                                <p>
                                                                    {(() => {
                                                                        if (product?.price != 0) {
                                                                            return (
                                                                                <div className="productPrice">
                                                                                    <span>{numberFormat(product?.price)}</span>
                                                                                    <del>{numberFormat(product?.product_cut_price)}</del>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })()}
                                                                    <div className="productRatings">
                                                                        {product?.product_rating}
                                                                        <AiTwotoneStar className="ratingIcons" />
                                                                    </div>
                                                                </p>
                                                            </div>
                                                            <p>{product?.short_desc}</p>
                                                            <div className="btnMainProduct">
                                                                <button
                                                                    className={`addPToCartBtn ${product?.price === 0 || product?.product_availability === "Out OF Stock" ? "disabledBtn" : ""}`}
                                                                    onClick={() => addToCart(product)}
                                                                    disabled={product?.price === 0 || product?.product_availability === "Out OF Stock"}
                                                                >
                                                                    Add To Cart
                                                                </button>
                                                                <button
                                                                    className={`buyPItNowBtn ${product?.price === 0 || product?.product_availability === "Out OF Stock" ? "disabledBtn" : ""}`}
                                                                    onClick={() => buyItNow(product)}
                                                                    disabled={product?.price === 0 || product?.product_availability === "Out OF Stock"}
                                                                >
                                                                    Buy It Now
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                            :
                                            <div>Data Not founds</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
            </div>
        </div>
    )
}

export default AyurvedaProducts
