import React, { useEffect, useState } from 'react'
import "../App.scss";
import ContentWrapper from '../components/contentWrapper/ContentWrapper';
import payment from "../assets/payment-footer.png";
import { Link, useLocation } from 'react-router-dom';
import { MdLocationPin, MdWifiCalling3, MdMarkEmailUnread } from 'react-icons/md';
import { BsFacebook } from 'react-icons/bs';
import { AiFillTwitterCircle, AiOutlineInstagram, AiOutlineYoutube } from 'react-icons/ai';
import { FaLinkedin, FaPinterestP } from 'react-icons/fa';
import { getSingleDataApi, userNewsLetterAPi } from '../utils/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { GoPerson } from "react-icons/go";

const Footer = () => {
    const [paramurl, setParamURL] = useState(null);
    const pathname = window.location.href.split("/").pop();

    const [email, setEmail] = useState("");

    useEffect(() => {
        matchedTopBarParam(pathname);
    }, [pathname]);

    const matchedTopBarParam = (pathname) => {
        if (pathname) {
            getSingleDataApi(`landing-page-matched-params-urls/${pathname}`).then((res) => {
                setParamURL(...[res.data?.paramsurl]);
            })
        }
    }
    console.log("paramurl",paramurl)
    const sweetAlertSuccess = () => {
        Swal.fire({
            title: 'Thank You!',
            text: 'Your sunscription has been added',
            icon: 'success'
        });
    }
    const handleNewsLetterSubmit = async (e) => {
        e.preventDefault();
        if (email) {
            userNewsLetterAPi("add-newsletter", email).then((res) => {
                if (res.data.status === 1) {
                    sweetAlertSuccess();
                }
                else {
                    toast.error("Unable sent Newsletter");
                }
            })
        }
        else {
            toast.error("Invalid Email ID");
        }
    }
    return (
        <div className="footerSection">
            {(() => {
                if (paramurl?.product_type == "Landing Page" || paramurl?.product_type == "LP" || paramurl?.product_type == "New Landing Page") {
                    return (
                        <ContentWrapper>
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-md-4 col-sm-6">
                                        <div className="footerNewsletter">
                                            <form method="post" onSubmit={handleNewsLetterSubmit}>
                                                <h4>Sign-up for our newsletter</h4>
                                                <p>Get latest update, exciting offers and free shipping in India when you subscribe to our newsletter</p>
                                                <input type="email" name="new_email" placeholder="Your Email Address" id="new_email" required={true} onChange={e => setEmail(e.target.value)} />
                                                <button type="submit">SUBSCRIBE</button>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="blockFooter">
                                            <div className="titleBlockFooter">
                                                Information Links
                                            </div>
                                            <div className="contentBlockFooter">
                                                <ul>
                                                    <li>
                                                        <Link to="/term-and-condition">Terms & Conditions</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/privacy-Policy">Privacy Policy</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/refund-policy">Refund & Cancellation Policy</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/shipping-policy">Shipping / Delivery Policy</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="blockFooter">
                                            <div className="titleBlockFooter">
                                                Connect With Us
                                            </div>
                                            <div className="contentBlockFooter">
                                                <ul>
                                                    {(() => {
                                                        if (paramurl?.footer_address != null) {
                                                            return (
                                                                <li className="d-flex">
                                                                    <MdLocationPin className="footerConnectIcon" />
                                                                    <span>
                                                                        {paramurl?.footer_address}
                                                                    </span>
                                                                </li>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <li className="d-flex">
                                                                    <MdLocationPin className="footerConnectIcon" />
                                                                    <span>
                                                                        6th Floor, Unit Number 603, Mercantile House, K.G. Marg, New Delhi, Delhi, 110001
                                                                    </span>
                                                                </li>
                                                            )
                                                        }
                                                    })()}
                                                    {(() => {
                                                        if (paramurl?.display_mobile_number != null) {
                                                            return (
                                                                <li>
                                                                    <MdWifiCalling3 className="footerConnectIcons" />
                                                                    <span>+91-{paramurl?.display_mobile_number}</span>
                                                                </li>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <li>
                                                                    <MdWifiCalling3 className="footerConnectIcons" />
                                                                    <span>+91-9319728256</span>
                                                                </li>
                                                            )
                                                        }
                                                    })()}

                                                    <li>
                                                        <MdMarkEmailUnread className="footerConnectIcons" />
                                                        <span>support@satkartar.co.in</span>
                                                    </li>
                                                </ul>
                                                <ul style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <li><Link aria-label="facebook" title="facebook" name="facebook" to="https://www.facebook.com/SatKartarGroup/" target="_blank"><BsFacebook /></Link></li>
                                                    <li><Link aria-label="twitter" title="twitter" name="twitter" to="https://twitter.com/SKShoppingLtd" target="_blank"><AiFillTwitterCircle /></Link></li>
                                                    <li><Link aria-label="linkedin" title="linkedin" name="linkedin" to="https://www.linkedin.com/company/sat-kartar-shopping-private-limited" target="_blank"><FaLinkedin /></Link></li>
                                                    <li><Link aria-label="youtube" title="youtube" name="youtube" to="https://www.youtube.com/@satkartarofficial/" target="_blank"><AiOutlineYoutube /></Link></li>
                                                    <li><Link aria-label="pinterest" title="pinterest" name="pinterest" to="https://in.pinterest.com/SatKartarOfficial/" target="_blank"><FaPinterestP /></Link></li>
                                                    <li><Link aria-label="instagram" title="instagram" name="instagram" to="https://www.instagram.com/satkartarofficial/" target="_blank"><AiOutlineInstagram /></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ContentWrapper>
                    )
                }
                else {
                    return (
                        <ContentWrapper>
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-md-3 col-sm-6">
                                        <div className="footerNewsletter">
                                            <form method="post" onSubmit={handleNewsLetterSubmit}>
                                                <h4>Sign-up for our newsletter</h4>
                                                <p>Find out all about our latest offers, new products, the science of Ayurved and a lot more in our newsletters!</p>
                                                <input type="email" name="new_email" placeholder="Your Email Address" id="new_email" required={true} onChange={e => setEmail(e.target.value)} />
                                                <button type="submit">SUBSCRIBE</button>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <div className="blockFooter">
                                            <div className="titleBlockFooter">
                                                Usefull Links
                                            </div>
                                            <div className="contentBlockFooter">
                                                <ul>
                                                    <li>
                                                        <Link to="/products">Product</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/deals">Deals</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/ingredients">Ingredients</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/about-us">About Us</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/contact-us">Contact Us</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/shop-by-concern">Shop By Concern</Link>
                                                    </li>
                                                    <li>
                                                        <Link target="_blank" to="https://www.satkartar.co.in/blog/">Blog</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <div className="blockFooter">
                                            <div className="titleBlockFooter">
                                                Information Links
                                            </div>
                                            <div className="contentBlockFooter">
                                                <ul>
                                                    <li>
                                                        <Link to="/term-and-condition">Terms & Conditions</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/privacy-Policy">Privacy Policy</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/refund-policy">Refund & Cancellation Policy</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/shipping-policy">Shipping / Delivery Policy</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/testimonial">Testimonial</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/faqs">FAQS</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <div className="blockFooter">
                                            <div className="titleBlockFooter">
                                                Connect With Us
                                            </div>
                                            <div className="contentBlockFooter">
                                                <ul>
                                                    <li>
                                                        <GoPerson className="footerConnectIcons" />
                                                        <span><b>Sat Kartar Shopping Ltd</b></span>
                                                    </li>
                                                    <li className="d-flex">
                                                        <MdLocationPin className="footerConnectIcon" />
                                                        <span>
                                                            6th Floor, Unit Number 603, Mercantile House, K.G. Marg, New Delhi, Delhi, 110001
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <MdWifiCalling3 className="footerConnectIcons" />
                                                        <span>+91-9319728256</span>
                                                    </li>
                                                    <li>
                                                        <MdMarkEmailUnread className="footerConnectIcons" />
                                                        <span>support@satkartar.co.in</span>
                                                    </li>
                                                </ul>
                                                <ul style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <li><Link aria-label="facebook" title="facebook" name="facebook" to="https://www.facebook.com/SatKartarGroup/" target="_blank"><BsFacebook /></Link></li>
                                                    <li><Link aria-label="twitter" title="twitter" name="twitter" to="https://twitter.com/SKShoppingLtd" target="_blank"><AiFillTwitterCircle /></Link></li>
                                                    <li><Link aria-label="linkedin" title="linkedin" name="linkedin" to="https://www.linkedin.com/company/sat-kartar-shopping-private-limited" target="_blank"><FaLinkedin /></Link></li>
                                                    <li><Link aria-label="youtube" title="youtube" name="youtube" to="https://www.youtube.com/@satkartarofficial/" target="_blank"><AiOutlineYoutube /></Link></li>
                                                    <li><Link aria-label="pinterest" title="pinterest" name="pinterest" to="https://in.pinterest.com/SatKartarOfficial/" target="_blank"><FaPinterestP /></Link></li>
                                                    <li><Link aria-label="instagram" title="instagram" name="instagram" to="https://www.instagram.com/satkartarofficial/" target="_blank"><AiOutlineInstagram /></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ContentWrapper>
                    )
                }
            })()}
            <div className="footerBottom">
                <ContentWrapper>
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <address>
                                        Sat Kartar Shopping Ltd © 2024. All Rights Reserved
                                    </address>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="footerPayment">
                                        <img src={payment} alt="footer-payment" title="footer-payment" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
            </div>
        </div>
    )
}

export default Footer
