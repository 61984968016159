import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useCart } from 'react-use-cart';
import { fetchDataFromApi, getCategoryProductApi } from '../utils/api';
import { toast } from 'react-toastify';
import { MetaTags } from 'react-meta-tags';
import { numberFormat } from '../components/numberFormate';
import TagManager from 'react-gtm-module'
import ContentWrapper from '../components/contentWrapper/ContentWrapper';
import { FiMenu } from 'react-icons/fi';
import Img from '../components/lazyLoadImage/Img';
import { AiTwotoneStar } from 'react-icons/ai';

const CategoryProduct = () => {
    const { addItem } = useCart();
    const navigate = useNavigate();
    const { slug } = useParams();
    const pathname = window.location.href;
    const [categorytitle, setCategoryTitle] = useState([]);
    const [product, setProduct] = useState([]);
    const userid = JSON.parse(localStorage.getItem('userdata'))?.id;
    const [showFullDescription, setFullDescription] = useState(false);

    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/img/product/";
    const BASE_URL1 = "https://backendsatkartar.satkartar.co.in/";

    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => { setIsReadMore(!isReadMore) };


    useEffect(() => {
        getCategoryProduct(slug)
    }, [slug])

    const getCategoryProduct = (slug) => {
        getCategoryProductApi(`get-category-product/${slug}`).then((res) => {
            if (res?.data.status === 1) {
                setProduct(...[res?.data.product]);
                setCategoryTitle(...[res?.data.category]);
            }
            else {
                toast.warning("Check your connection!!");
            }
        })
    }

    const addToCart = (product) => {
        addItem(product);
        toast.success("Product has been added in your cart!");
        TagManager.dataLayer({
            dataLayer: {
                event: "add_to_cart",
                pagePath: pathname,
                pageTitle: categorytitle?.cat_page_title,
                userId: "SK-" + userid,
                eventModel: {
                    ecomm_totalvalue: product.price,
                    ecomm_pagetype: "Cart",
                    value: product.price,
                    currency: "INR",
                    items: [
                        {
                            id: product.id,
                            name: product.name,
                            brand: "Satkartar",
                            price: product.price,
                            quantity: 1,
                        }
                    ]
                }
            },
        });
    }
    const buyItNow = (product) => {
        addItem(product);
        TagManager.dataLayer({
            dataLayer: {
                event: "begin_checkout",
                pagePath: pathname,
                userId: "SK-" + userid,
                pageTitle: categorytitle?.cat_page_title,
                eventModel: {
                    ecomm_totalvalue: product.price,
                    ecomm_pagetype: "Cart",
                    value: product.price,
                    currency: "INR",
                    items: [
                        {
                            id: product.id,
                            name: product.name,
                            brand: "Satkartar",
                            price: product.price,
                            quantity: 1,
                        }
                    ]
                }
            },
        });
        if (userid) {
            navigate("/delivery-address");
        }
        else {
            navigate("/checkout-login");
        }
    }
    let productSchema = {
        "breadcrum": {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://www.satkartar.co.in/"
            }, {
                "@type": "ListItem",
                "position": 2,
                "name": "Products",
                "item": "https://www.satkartar.co.in/products"
            }]
        },
        "@context": "https://schema.org",
        "@type": "ItemList",
        "url": `${pathname}`,
        "numberOfItems": `${product.length}`,
        "itemListElement": [
            product?.map((product, index) => (
                {
                    "@type": "Product",
                    "image": `${BASE_URL + product?.image}`,
                    "url": `${BASE_URL1 + `product/${product?.product_slug_url}`}`,
                    "name": `${product.name}`,
                    "description": `${product?.short_desc}`,
                    "position": `${index + 1}`,
                    "offers": {
                        "@type": "AggregateOffer",
                        "url": `${BASE_URL1 + `product/${product?.product_slug_url}`}`,
                        "lowPrice": `${product?.price}.00`,
                        "highPrice": `${product?.product_cut_price}.00`,
                        "priceCurrency": "INR",
                        "offerCount": `${index + 10}`,
                        "priceSpecification": {
                            "@type": "UnitPriceSpecification",
                            "lowPrice": `${product?.price}.00`,
                            "highPrice": `${product?.product_cut_price}.00`,
                            "priceCurrency": "INR",
                        },
                        "availability": "InStock",
                    },
                    "brand": {
                        "@type": "Brand",
                        "name": "SATKARTAR"
                    },
                    "aggregateRating": {
                        "@type": "AggregateRating",
                        "ratingValue": `${product?.product_rating}`,
                        "reviewCount": `${product?.price}`
                    },
                }
            )),
        ]
    }
    return (
        <div className="productSection">
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(productSchema) }}
            />
            <MetaTags>
                <title>{categorytitle?.cat_page_title}</title>
                <link rel="canonical" href={pathname} />
                <meta name="title" content={categorytitle?.cat_page_title} />
                <meta name="description" content={categorytitle?.cat_meta_desc} />
                <meta name="keywords" content="" />
                <meta property="og:url" content={pathname} />
                <meta property="og:title" content={categorytitle?.cat_page_title} />
                <meta property="og:description"
                    content={categorytitle?.cat_meta_desc} />
                <meta name="description" content={categorytitle?.cat_meta_desc} />
            </MetaTags>
            <div className="breadcrumbs catdespbread">
                <ContentWrapper>
                    <ul className="items">
                        <li className="item home">
                            <Link to="/" title="Go to Home Page"><span>Home</span></Link>
                        </li>
                        <li className="item home">
                            <Link to="/shop-by-concern" title="Go to Home Page"><span>Shop by concern</span></Link>
                        </li>
                        <li className="items product"><span>{categorytitle?.category_name}</span></li>
                    </ul>
                </ContentWrapper>
            </div>
            <div className="col-lg-12">
                <ContentWrapper>
                    <div className="row bg-white">
                        <div className="col-md-12 col-sm-12">
                            <div className="productTopBar productTopBars">
                                <div className="productViewTitle catdespstitle">
                                    {(() => {
                                        if (categorytitle?.cat_page_title) {
                                            return (
                                                <h1>{categorytitle?.cat_page_title}</h1>
                                            )
                                        }
                                        else {
                                            <h1>{categorytitle?.cat_name}</h1>
                                        }
                                    })()}
                                </div>
                            </div>
                            <div className="productView mt-4">
                                <div className="col-md-12 col-sm-12">
                                    {(() => {
                                        if (categorytitle?.cat_desc) {
                                            return (
                                                <>
                                                    {isReadMore ?
                                                        <div className="catdesps" dangerouslySetInnerHTML={{ __html: categorytitle?.cat_desc.slice(0, 350) }}></div>
                                                        : <div className="catdesps" dangerouslySetInnerHTML={{ __html: categorytitle?.cat_desc }}></div>}
                                                    {categorytitle?.cat_desc.length > 350 &&
                                                        <button className="btnreadmore" onClick={toggleReadMore}>
                                                            {isReadMore ? '...read more' : ' ...show less'}
                                                        </button>
                                                    }
                                                </>
                                            )
                                        }
                                    })()}
                                    <div className="row">
                                        {product.length > 0 ? product.map((product, index) => (
                                            <div className="col-md-3 col-sm-12" key={index}>
                                                <div className="appSinglePricingCard productPageCard">
                                                    <div className="pricingTopContent">
                                                        <div className="productImg">
                                                            {(() => {
                                                                if (product?.product_type === "Vitamin & Nutrition") {
                                                                    return (
                                                                        <Link to={`/vitamin-and-supplements/${product?.product_slug_url}`}>
                                                                            <Img src={BASE_URL + product?.image} alt={product?.product_alt_name}></Img>
                                                                        </Link>
                                                                    )
                                                                }
                                                                else if (product?.product_type === "Nutraceuticals") {
                                                                    return (
                                                                        <Link to={`/herbal/${product?.product_slug_url}`}>
                                                                            <Img src={BASE_URL + product?.image} alt={product?.product_alt_name}></Img>
                                                                        </Link>
                                                                    )
                                                                }
                                                                else {
                                                                    return (
                                                                        <Link to={`/product/${product?.product_slug_url}`}>
                                                                            <Img src={BASE_URL + product?.image} alt={product?.product_alt_name}></Img>
                                                                        </Link>
                                                                    )
                                                                }
                                                            })()}
                                                        </div>
                                                        <div className="productContentList">
                                                            <div className="pricingList">
                                                                {(() => {
                                                                    if (product?.product_type === "Vitamin & Nutrition") {
                                                                        return (
                                                                            <Link to={`/vitamin-and-supplements/${product?.product_slug_url}`}>
                                                                                <h4 className="title productnames">{product?.page_title}</h4>
                                                                            </Link>
                                                                        )
                                                                    }
                                                                    else if (product?.product_type === "Nutraceuticals") {
                                                                        return (
                                                                            <Link to={`/herbal/${product?.product_slug_url}`}>
                                                                                <h4 className="title productnames">{product?.page_title}</h4>
                                                                            </Link>
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (
                                                                            <Link to={`/product/${product?.product_slug_url}`}>
                                                                                <h4 className="title productnames">{product?.page_title}</h4>
                                                                            </Link>
                                                                        )
                                                                    }
                                                                })()}
                                                                <p>
                                                                    <div className="productPrice">
                                                                        <span>{numberFormat(product?.price)}</span>
                                                                        <del>{numberFormat(product?.product_cut_price)}</del>
                                                                    </div>
                                                                    <div className="productRatings">
                                                                        {product?.product_rating}
                                                                        <AiTwotoneStar className="ratingIcons" />
                                                                    </div>
                                                                </p>
                                                            </div>
                                                            <div className="btnMainProduct">
                                                                <button
                                                                    className={`addPToCartBtn ${product?.price === 0 || product?.product_availability === "Out OF Stock" ? "disabledBtn" : ""}`}
                                                                    onClick={() => addToCart(product)}
                                                                    disabled={product?.price === 0 || product?.product_availability === "Out OF Stock"}
                                                                >
                                                                    Add To Cart
                                                                </button>
                                                                <button
                                                                    className={`buyPItNowBtn ${product?.price === 0 || product?.product_availability === "Out OF Stock" ? "disabledBtn" : ""}`}
                                                                    onClick={() => buyItNow(product)}
                                                                    disabled={product?.price === 0 || product?.product_availability === "Out OF Stock"}
                                                                >
                                                                    Buy It Now
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                            :
                                            <div className="productnotfound">
                                                <p>Sorry! It looks like currently, there are no product listed in this category.</p>
                                                <Link to="/shop-by-concern">Go back to explore other categories.</Link>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
            </div>
        </div>
    )
}

export default CategoryProduct